import {css} from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import {useEffect, useRef, useState} from 'react';
import {
  SCROLL_BUTTAN_SPEED,
  SCROLL_UP_SPEED,
} from 'slices/landing/lib/constants';
import hotelsImg from 'source/components/illustration/assets/hotels-cards.webp';
import safariSearch from 'source/components/illustration/assets/safari-search.webp';
import {Routes} from 'source/utilities/network/url';
import ButtonLink from '../button-link';
import {Container} from '../container';

const Wrapper = styled(Container)<{isScroll: boolean}>`
  @keyframes scrollUp {
    0% {
      transform: translateY(320px);
    }
    75% {
      transform: translateY(-75px);
    }
    100% {
      transform: translateY(-48px);
    }
  }

  padding: 0 !important;
  position: relative;
  transform: translateY(425px);
  transition: transform 1s;
  width: 100%;

  @media (max-width: 1440px) {
    transform: translateY(350px);
  }

  @media (max-width: 930px) {
    transform: translateY(300px);
  }

  @media (max-width: 800px) {
    transform: translateY(250px);
  }

  @media (max-width: 700px) {
    transform: translateY(200px);
  }

  @media (max-width: 600px) {
    transform: translateY(150px);
  }

  @media (max-width: 480px) {
    transform: translateY(125px);
  }

  @media (max-width: 450px) {
    transform: translateY(100px);
  }

  @media (max-width: 360px) {
    transform: translateY(70px);
  }

  ${({isScroll}) =>
    isScroll &&
    css`
      animation: scrollUp 1.2s ease-in-out forwards;
    `}
`;

const ResultWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  height: 58%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: -1px;

  @media (max-width: 1440px) {
    max-width: 960px;
    height: 57%;
    width: calc(100% - 62px);
    left: 31px;
    right: 31px;
  }

  @media (max-width: 980px) {
    height: 56%;
  }

  @media (max-width: 820px) {
    height: 55%;
  }

  @media (max-width: 690px) {
    height: 54%;
  }

  @media (max-width: 620px) {
    height: 53%;
  }

  @media (max-width: 540px) {
    height: 52%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
  }

  @media (max-width: 440px) {
    height: 51%;
  }

  @media (max-width: 390px) {
    height: 50%;
  }
  @media (max-width: 360px) {
    height: 49%;
  }
`;

const StyledImageResult = styled(Image)`
  @keyframes scrollDown {
    0% {
      transform: translateY(0);
    }

    20% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-70%);
    }
  }

  width: 100%;
  height: fit-content;
  object-fit: contain;
  object-position: top;
  background: transparent;
  animation: scrollDown 11s ease-in-out forwards;
`;

const ButtonWrapper = styled.div<{isScroll: boolean}>`
  @keyframes push {
    0% {
      transform: translateY(120px);
    }
    85% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
  display: flex;
  justify-content: center;
  position: relative;
  transform: translateY(120px);
  transition: transform 500ms;
  z-index: -1;

  ${({isScroll}) =>
    isScroll &&
    css`
      animation: push 1.2s ease-in-out forwards;
    `}
`;

const ImageBox = styled.div`
  max-width: 1140px;
  margin: 0 32px;
  width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;

  @media (max-width: 1440px) {
    max-width: 1024px;
    padding: 0 32px;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;

const StyledImage = styled(Image)`
  background: transparent;
  object-fit: contain;
  object-position: top;
  height: fit-content;
  margin-top: 48px;
  border-radius: 5px;
  box-shadow: 0 10px 49px 0 ${({theme}) => theme.palette.imageExtranetBoxShadow};
  width: 100%;

  @media (max-width: 480px) {
    margin-top: 37px;
  }
`;

const AnimationSearchResult = () => {
  const {t} = useTranslation('index');
  const timerScrollButtonRef = useRef<NodeJS.Timeout | null>(null);

  const [scrollUp, setScrollUp] = useState(false);
  const [scrollButton, setScrollButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setScrollUp(true);
    }, SCROLL_UP_SPEED);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (scrollUp) {
      timerScrollButtonRef.current = setTimeout(() => {
        setScrollButton(true);
        document.body.style.overflow = '';
      }, SCROLL_BUTTAN_SPEED);
    }

    return () => {
      if (timerScrollButtonRef.current) {
        clearTimeout(timerScrollButtonRef.current);
      }
      document.body.style.overflow = '';
    };
  }, [scrollUp]);

  return (
    <Wrapper isScroll={scrollUp}>
      <ButtonWrapper isScroll={scrollButton}>
        <ButtonLink href={Routes.registration}>
          {t('main_screen.button')}
        </ButtonLink>
      </ButtonWrapper>
      <ImageBox>
        <StyledImage src={safariSearch} alt={t('main_screen.alt')} />
        <ResultWrapper>
          {scrollUp && (
            <StyledImageResult src={hotelsImg} alt={t('main_screen.alt')} />
          )}
        </ResultWrapper>
      </ImageBox>
    </Wrapper>
  );
};

export default AnimationSearchResult;
