import {css} from '@emotion/react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {useEffect, useRef, useState} from 'react';
import {
  ANUMATION_INPUT_ICON_SIZE,
  ANUMATION_INPUT_ICON_WIDTH,
  CURSOR_BLINK_SPEED,
  FLY_AWAY_ANUMATION_SPEED,
  TYPING_SPEED,
} from 'slices/landing/lib/constants';
import {Icon} from 'source/components/icon';

const AnimationInput = styled.div<{isFlyAway: boolean}>`
  @keyframes flyAway {
    0% {
      transform: scale(1) translateZ(0);
      opacity: 1;
    }
    100% {
      transform: scale(0) translateZ(-1000px);
      opacity: 0;
    }
  }

  width: 584px;
  min-height: 50px;
  max-height: 50px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border: 1px solid ${({theme}) => theme.palette.extranetBorder};
  box-shadow: 0 12px 56px 0 ${({theme}) => theme.palette.backgroundBoxShadow};
  border-radius: 23px;

  ${({isFlyAway}) =>
    isFlyAway &&
    css`
      animation: flyAway 1s forwards;
    `}

  @media (max-width:640px) {
    width: 85%;
  }
`;

const PrintWrapper = styled.div`
  margin-left: 5px;
  position: relative;
  font-family: Roboto;
  color: ${({theme}) => theme.palette.fontExtranet};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;

  span {
    position: absolute;
  }

  @media (max-width: 450px) {
    width: 85%;
    font-size: 10px;
    line-height: 15px;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const AnimationSearchInput = () => {
  const {t} = useTranslation('index');

  const [text, setText] = useState('');
  const [isFlyAway, setIsFlyAway] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(true);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fullText = t('main_screen.input_text');

  useEffect(() => {
    if (text.length < fullText.length) {
      typingTimeoutRef.current = setTimeout(() => {
        setText(fullText.slice(0, text.length + 1));
      }, TYPING_SPEED);
    }

    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, [text]);

  useEffect(() => {
    const flyAwayTimeout = setTimeout(() => {
      setIsFlyAway(true);
    }, FLY_AWAY_ANUMATION_SPEED);

    return () => clearTimeout(flyAwayTimeout);
  }, []);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setCursorVisible((visible) => !visible);
    }, CURSOR_BLINK_SPEED);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <AnimationInput isFlyAway={isFlyAway}>
      <Box>
        <Icon
          name="magnifier"
          height={ANUMATION_INPUT_ICON_SIZE}
          width={ANUMATION_INPUT_ICON_SIZE}
        />
        <PrintWrapper>
          {text}
          {cursorVisible && <span>|</span>}
        </PrintWrapper>
      </Box>
      <Icon
        name="search-navs"
        height={ANUMATION_INPUT_ICON_SIZE}
        width={ANUMATION_INPUT_ICON_WIDTH}
      />
    </AnimationInput>
  );
};

export default AnimationSearchInput;
