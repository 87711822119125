import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {FC, PropsWithChildren, useEffect, useRef, useState} from 'react';
import {Icon} from 'source/components/icon';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ACCORDION_CONTENT_PADDING_MULTIPLIER} from 'slices/landing/lib/constants';
import {Text} from '../text';
import {Title} from '../title';

const Wrapper = styled.div<{isActive: boolean}>`
  max-width: 440pxpx;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  margin: 16px 0;
`;

const TitleWrapper = styled.div<{isActive: boolean}>`
  position: relative;
  z-index: 10;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 12px 24px;
  border-radius: 16px 16px 0 0;
  background: ${({theme}) => theme.palette.defaultBackground};
  transition:
    background 0.15s,
    color 0.15s;

  &:hover {
    h4 {
      color: ${({theme}) => theme.palette.fontPrimary};
    }
    path {
      stroke: ${({theme}) => theme.palette.fontPrimary};
    }
  }

  ${({isActive, theme}) =>
    isActive &&
    css`
      color: ${theme.palette.fontAccent};
      background: ${theme.palette.primaryAccent};
      &:hover {
        h4 {
          color: ${theme.palette.fontAccent};
        }
        path {
          stroke: ${theme.palette.fontAccent};
        }
      }
    `}

  @media (max-width: 375px) {
    gap: 10px;
    padding: 7px 16px;
  }
`;

const CloseTabBox = styled.div<{isActive: boolean}>`
  transform: rotate(45deg);
  transition: transform 0.3s;

  ${({isActive}) =>
    isActive &&
    css`
      transform: rotate(90deg);
    `}
`;

const StyledIcon = styled(Icon)<{isActive: boolean}>`
  path {
    stroke: ${({theme}) => theme.palette.extranetStroke};
  }

  ${({isActive, theme}) =>
    isActive &&
    css`
      path {
        stroke: ${theme.palette.defaultBackground};
      }
    `}

  @media (max-width: 375px) {
    width: 10px;
    height: 10px;
  }
`;

const TitleTab = styled.div``;

const ContentWrapper = styled.div<{isActive: boolean; padding?: number}>`
  border-radius: 0 0 16px 16px;
  padding: 0 ${({padding}) => padding}px;
  background: ${({theme}) => theme.palette.backgroundIconExtranet_1};
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition:
    max-height 0.3s ease,
    padding 0.3s ease,
    opacity 0.3s ease;

  ${({isActive, padding}) =>
    isActive &&
    css`
      padding: ${padding}px;
      opacity: 1;
    `}
`;

const StyledText = styled(Text)`
  color: ${({theme}) => theme.palette.fontExtranet};
`;

const StyledTitle = styled(Title)<{isActive: boolean}>`
  color: ${({theme}) => theme.palette.fontExtranet};

  ${({isActive, theme}) =>
    isActive &&
    css`
      color: ${theme.palette.fontAccent};
    `}
`;

interface Props extends PropsWithChildren {
  title: string;
}

const TabItem: FC<Props> = ({title, children}) => {
  const isMobile = useDeviceDetection('mobile');
  const ACCORDION_CONTENT_PADDING = isMobile ? 16 : 24;

  const [isActive, setIsActive] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const clickHandler = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.style.maxHeight = isActive
        ? `${
            contentRef.current.scrollHeight +
            ACCORDION_CONTENT_PADDING * ACCORDION_CONTENT_PADDING_MULTIPLIER
          }px`
        : '0';
    }
  }, [isActive]);

  return (
    <Wrapper isActive={isActive}>
      <TitleWrapper isActive={isActive} onClick={clickHandler}>
        <TitleTab>
          <StyledTitle type="h4" isActive={isActive} size="SM2">
            {title}
          </StyledTitle>
        </TitleTab>
        <CloseTabBox isActive={isActive}>
          <StyledIcon isActive={isActive} name="close" height={14} width={14} />
        </CloseTabBox>
      </TitleWrapper>
      <ContentWrapper
        ref={contentRef}
        isActive={isActive}
        padding={ACCORDION_CONTENT_PADDING}
      >
        <StyledText size="XS2">{children}</StyledText>
      </ContentWrapper>
    </Wrapper>
  );
};

export default TabItem;
