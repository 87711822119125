import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {useEffect, useState} from 'react';
import {css} from '@emotion/react';
import {
  DOTS,
  INTERVAL_FOR_NEXT_DOTS,
  INTERVAL_FOR_NEXT_SLIDE,
  NUMBER_OF_POINTS,
  SLIDES,
  URL_BACKGROUNG_IMAGE_USER_POINT,
  URL_BACKGROUNG_SVG_MAP_POINT,
} from 'slices/landing/lib/constants';
import SectionTag from '../section-tag';
import SliderPoint from '../slider-point';
import {Title} from '../title';
import {Container} from '../container';

const Wrapper = styled.div`
  min-height: 625px;
  padding: 70px 0;
  background: ${({theme}) => theme.palette.defaultBackground};

  @media (max-width: 480px) {
    padding-bottom: 0px;
    min-height: 480px;
  }

  @media (max-width: 450px) {
    padding-top: 46px;
  }
`;

const Box = styled(Container)`
  position: relative;
  margin: 0 auto;
  padding-top: 157px;
`;

const TitleWrapper = styled.div`
  max-width: 608px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 670px;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const SlideWrapper = styled.div<{isActive: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 500ms ease-in-out;

  ${({isActive}) =>
    isActive &&
    css`
      opacity: 1;
    `}
`;

const AnimationPoints = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 484px;
  height: 484px;
  display: grid;
  grid-template-columns: repeat(7, 72px);
  background-image: url(${() => URL_BACKGROUNG_SVG_MAP_POINT});
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  user-select: none;

  @media (max-width: 1070px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 960px) {
    position: relative;
    margin-top: 100px;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    height: 340px;
    overflow: hidden;
  }

  @media (max-width: 450px) {
    grid-template-columns: repeat(4, 1fr);
    height: 330px;
    margin-top: 75px;
  }
`;

const UserPoint = styled.div<{isVisible: boolean}>`
  width: 72px;
  height: 86px;
  background-image: url(${() => URL_BACKGROUNG_IMAGE_USER_POINT});
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  user-select: none;

  opacity: 0;
  transform: scale(0.5);
  transition:
    opacity 2s ease,
    transform 2s ease;

  ${({isVisible}) =>
    isVisible &&
    css`
      opacity: 1;
      transform: scale(1);
    `}
`;

const BR = styled.span`
  display: block;
`;

const SliderAdvantages = () => {
  const {t} = useTranslation('index');
  const [activeSlide, setActiveSlide] = useState(0);
  const [visibleDots, setVisibleDots] = useState(DOTS[0]);
  const [_, setCurrentDotsIndex] = useState(0);

  // Отслеживает/изменяет массив индексов (порядковые номера grid элементов) которые будут отображены
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDotsIndex((previousIndex) => {
        // Переключение видимых точек массива [DOTS] по кругу
        const nextIndex = (previousIndex + 1) % DOTS.length;
        setVisibleDots(DOTS[nextIndex]);
        return nextIndex;
      });
    }, INTERVAL_FOR_NEXT_DOTS);

    return () => clearInterval(intervalId);
  }, [DOTS]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((previousSlide) => {
        const nextSlideIndex = (previousSlide + 1) % SLIDES.length;
        return nextSlideIndex;
      });
    }, INTERVAL_FOR_NEXT_SLIDE);

    return () => clearInterval(interval);
  }, [SLIDES.length]);

  return (
    <Wrapper>
      <Box>
        <TitleWrapper>
          <SectionTag text={t('slider_advantages.tag')}>
            {Array.from({length: SLIDES.length}, (_, index) => {
              return (
                <SliderPoint
                  key={String(index)}
                  isActive={activeSlide === index}
                  isVisible={index < activeSlide}
                  point={index + 1}
                />
              );
            })}
          </SectionTag>
          <SliderWrapper>
            {SLIDES.map((slide, index) => (
              <SlideWrapper isActive={activeSlide === index} key={slide.title}>
                <Title size="XL" type="h3">
                  {t(slide.title)}
                  <BR />
                  <span>{t(slide.accent)}</span>
                </Title>
              </SlideWrapper>
            ))}
          </SliderWrapper>
        </TitleWrapper>
        <AnimationPoints>
          {Array.from({length: NUMBER_OF_POINTS}, (_, index) => (
            <UserPoint key={index} isVisible={visibleDots.includes(index)} />
          ))}
        </AnimationPoints>
      </Box>
    </Wrapper>
  );
};

export default SliderAdvantages;
