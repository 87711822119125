import AboutRegistration from './ui/about-registration';
import Account from './ui/account';
import Advantages from './ui/advantages';
import Connection from './ui/connection';
import Faq from './ui/faq';
import MainScreen from './ui/main-screen';
import Promotion from './ui/promotion';
import SliderAdvantages from './ui/slider-advantages';
import Statistics from './ui/statistics';
import Tariffs from './ui/tariffs';

const Content = () => {
  return (
    <>
      <MainScreen />
      <Advantages />
      <AboutRegistration />
      <Promotion />
      <Tariffs />
      <SliderAdvantages />
      <Statistics />
      <Connection />
      <Faq />
      <Account />
    </>
  );
};

export default Content;
