import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {USERS_TYPES} from 'slices/landing/lib/constants';
import {Routes} from 'source/utilities/network/url';
import AdvantageItem from '../advantage-item';
import ButtonLink from '../button-link';
import {Container} from '../container';
import SectionTag from '../section-tag';
import {Text} from '../text';
import {Title} from '../title';

const Wrapper = styled.div`
  padding: 140px 0;
  background: ${({theme}) => theme.palette.backgroundExtranet};

  @media (max-width: 640px) {
    padding: 70px 0;
  }

  @media (max-width: 450px) {
    padding: 46px 0;
  }
`;

const Box = styled(Container)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 48px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 450px) {
    gap: 32px;
  }
`;

const TitleWrapper = styled.div`
  max-width: 575px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1023px) {
    max-width: 520px;
  }
`;

const TypesWrapper = styled.div`
  max-width: 504px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 168px;
  gap: 24px;

  @media (max-width: 1023px) {
    max-width: 576px;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }

  @media (max-width: 560px) {
    gap: 12px;
  }

  @media (max-width: 470px) {
    grid-template-rows: 128px;
    gap: 10px;
  }

  @media (max-width: 450px) {
    gap: 8px;
  }

  @media (max-width: 380px) {
    grid-template-rows: 110px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 32px;

  @media (max-width: 450px) {
    margin-top: 18px;
  }
  @media (max-width: 450px) {
    margin-top: 0;
  }
`;

const StyledText = styled(Text)`
  @media (max-width: 1440px) {
    max-width: 384px;
  }
  @media (max-width: 1024px) {
    max-width: 380px;
  }
  @media (max-width: 1023px) {
    max-width: 520px;
  }
  @media (max-width: 640px) {
    max-width: 460px;
  }
  @media (max-width: 450px) {
    max-width: 400px;
  }
`;

const BR = styled.span`
  display: block;

  @media (max-width: 1023px) {
    display: inline;
  }
  @media (max-width: 480px) {
    display: block;
  }
`;

const TextBR1 = styled.span`
  @media (max-width: 1440px) {
    display: block;
  }
  @media (max-width: 1023px) {
    display: block;
  }
`;

const TextBR2 = styled.span`
  display: block;
  @media (max-width: 1440px) {
    display: block;
  }
  @media (max-width: 1023px) {
    display: inline;
  }
`;

const AboutRegistration = () => {
  const {t} = useTranslation('index');
  return (
    <Wrapper>
      <Box>
        <TitleWrapper>
          <SectionTag text={t('about_registration.tag')} />
          <Title size="XL" type="h2">
            {t('about_registration.title_1')}
            <BR />
            {t('about_registration.title_2')}
            <BR />
            <span>{t('about_registration.title_accent')}</span>
          </Title>
          <StyledText size="SM2">
            {t('about_registration.subtitle_1')}
            <TextBR1 />
            {t('about_registration.subtitle_2')}
            <TextBR2 />
            {t('about_registration.subtitle_3')}
          </StyledText>
          <ButtonWrapper>
            <ButtonLink href={Routes.registration}>
              {t('about_registration.button')}
            </ButtonLink>
          </ButtonWrapper>
        </TitleWrapper>
        <TypesWrapper>
          {USERS_TYPES.map((type) => (
            <AdvantageItem
              key={type.title}
              title={type.title}
              icon={type.icon}
              iconColor={type.iconColor}
            />
          ))}
        </TypesWrapper>
      </Box>
    </Wrapper>
  );
};

export default AboutRegistration;
