import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {FC} from 'react';

const Wrapper = styled.div<{isActive: boolean; isVisible?: boolean}>`
  padding: 0 16px;
  border-radius: 10px;
  border: 1px;
  color: ${({theme}) => theme.palette.primaryAccent};
  box-shadow: 0 1px 1px 0 ${({theme}) => theme.palette.backgroundBoxShadow};

  ${({isActive, theme}) =>
    isActive &&
    css`
      color: ${theme.palette.fontAccent};
      background: ${theme.palette.primaryAccent};
    `}

  ${({isVisible, theme}) =>
    isVisible &&
    css`
      color: ${theme.palette.fontAccent};
      background: ${theme.palette.backgroundExtranet_2};
    `}
`;

interface Props {
  point: number;
  isVisible?: boolean;
  isActive?: boolean;
}

const SliderPoint: FC<Props> = ({point, isVisible, isActive = false}) => {
  return (
    <Wrapper isActive={isActive} isVisible={isVisible}>
      {point}
    </Wrapper>
  );
};

export default SliderPoint;
