import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 1204px;
  padding: 0 32px;

  @media (max-width: 1440px) {
    max-width: 1024px;
  }

  @media (max-width: 1023px) {
    max-width: 640px;
  }

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`;
