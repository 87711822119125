import styled from '@emotion/styled';
import {FC, PropsWithChildren} from 'react';
import {Icon} from 'source/components/icon';
import {Text} from '../text';

const Wrapper = styled.div`
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  box-shadow: 0 4px 12px 0 ${({theme}) => theme.palette.backgroundBoxShadow};
  background: ${({theme}) => theme.palette.primaryBackground};
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
  font-weight: 500;
  line-height: 22px;
  font-size: 16px;
  color: ${({theme}) => theme.palette.fontDefault};

  @media (max-width: 450px) {
    padding: 5px 8px;
  }
`;

interface Props extends PropsWithChildren {
  text: string;
}

const SectionTag: FC<Props> = ({text, children}) => {
  return (
    <Wrapper>
      <Icon name="sparks" width={20} height={20} />
      <Text size="SM">{text}</Text>
      {children}
    </Wrapper>
  );
};

export default SectionTag;
