import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {PROMOTIONS} from 'slices/landing/lib/constants';
import AdvantageItem from '../advantage-item';
import SectionTag from '../section-tag';
import {Text} from '../text';
import {Title} from '../title';
import {Container} from '../container';

const Wrapper = styled.div`
  padding: 140px 0;
  background: ${({theme}) => theme.palette.defaultBackground};

  @media (max-width: 640px) {
    padding: 70px 0;
  }

  @media (max-width: 450px) {
    padding: 46px 0;
  }
`;

const Box = styled(Container)`
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 48px;

  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 480px) {
    gap: 48px;
  }
  @media (max-width: 375px) {
    gap: 32px;
  }
`;

const TitleWrapper = styled.div`
  max-width: 608px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1024px) {
    max-width: 428px;
  }

  @media (max-width: 640px) {
    max-width: 100%;
  }
`;

const PromotionsWrapper = styled.div`
  max-width: 484px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  @media (max-width: 1023px) {
    max-width: 100%;
  }

  @media (max-width: 480px) {
    gap: 8px;
  }
`;

const BR = styled.span`
  display: block;
`;

const Promotion = () => {
  const {t} = useTranslation('index');

  return (
    <Wrapper>
      <Box>
        <TitleWrapper>
          <SectionTag text={t('promotion.tag')} />
          <Title size="XL" type="h2">
            {t('promotion.title')}
            <BR />
            <span>{t('promotion.title_accent')}</span>
          </Title>
          <Text size="SM2">{t('promotion.subtitle')}</Text>
        </TitleWrapper>
        <PromotionsWrapper>
          {PROMOTIONS.map((item) => (
            <AdvantageItem
              key={item.title}
              title={item.title}
              subtitle={item.subtitle}
              icon={item.icon}
              isNoWrapp
            />
          ))}
        </PromotionsWrapper>
      </Box>
    </Wrapper>
  );
};

export default Promotion;
