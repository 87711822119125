import styled from '@emotion/styled';
import {FC} from 'react';
import {Icon, IconName} from 'source/components/icon';
import {Link} from 'source/components/link';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 124px;
  background: ${({theme}) => theme.palette.defaultBackground};
  box-shadow: 0 12px 56px 0 ${({theme}) => theme.palette.backgroundBoxShadow};
  border: 1px solid ${({theme}) => theme.palette.extranetBorder};
  padding: 16px;
  border-radius: 16px;
  transition: box-shadow 0.7s;

  &:hover {
    box-shadow: ${({theme}) => theme.palette.extranetHoverBoxShadow};
  }

  @media (max-width: 570px) {
    padding: 8px 8px;
  }

  @media (max-width: 450px) {
    min-height: 83px;
  }
`;

interface Props {
  iconName: IconName;
  height: number;
  width: number;
  href?: string;
}

const IconContainer: FC<Props> = ({iconName, height, width, href}) => {
  if (!href) {
    return (
      <Wrapper>
        <Icon name={iconName} height={height} width={width} />
      </Wrapper>
    );
  }

  return (
    <Link href={href} target="_blank">
      <Wrapper>
        <Icon name={iconName} height={height} width={width} />
      </Wrapper>
    </Link>
  );
};

export default IconContainer;
