import {theme} from 'source/utilities/global-style';
import {
  CONNECT_BNOVO,
  CONNECT_REALTY_CALENDAR,
  CONNECT_TRAVELLINE,
  ZHILIBYLI_HOTELIERS_URL,
  ZHILIBYLI_MOBILE_APPS,
  ZHILIBYLI_SUPPORT,
  ZHILIBYLI_TELEGRAM,
  ZHILIBYLI_URL,
  ZHILIBYLI_WHATSAPP,
} from 'source/utilities/guards/environment';
import {CardType, FaqType, IconCardType} from './types';

export const TYPING_SPEED = 100;
export const CURSOR_BLINK_SPEED = 500;
export const FLY_AWAY_ANUMATION_SPEED = 3600;
export const ANUMATION_INPUT_ICON_SIZE = 24;
export const ANUMATION_INPUT_ICON_WIDTH = 48;
export const SCROLL_UP_SPEED = 3500;
export const SCROLL_BUTTAN_SPEED = 100;
export const INTERVAL_FOR_NEXT_DOTS = 2500;
export const INTERVAL_FOR_NEXT_SLIDE = 4000;
export const NUMBER_OF_POINTS = 35;
export const NUMBER_OF_NOTIFICATIONS_IN_ANIMATION = 3;
export const ACCORDION_CONTENT_PADDING_MULTIPLIER = 2;
export const URL_BACKGROUNG_IMAGE_USER_POINT = 'extranet/user-point.svg';
export const URL_BACKGROUNG_SVG_MAP_POINT = 'extranet/map-point.svg';
export const DELAY_MULPLIER = 2;

export const USERS_TYPES: CardType[] = [
  {
    title: 'about_registration.users_types.person',
    icon: 'person',
    iconColor: theme.palette.backgroundIconExtranet_2,
  },
  {
    title: 'about_registration.users_types.juridical_person',
    icon: 'bag',
  },
  {
    title: 'about_registration.users_types.individual_entrepreneur',
    icon: 'individual',
  },
  {
    title: 'about_registration.users_types.self_employed',
    icon: 'self',
  },
];

export const ADVANTAGES: CardType[] = [
  {
    title: 'advantages.payment_methods.title',
    subtitle: 'advantages.payment_methods.subtitle',
    icon: 'bill',
  },
  {
    title: 'advantages.manage_object_price.title',
    subtitle: 'advantages.manage_object_price.subtitle',
    icon: 'building',
  },
  {
    title: 'advantages.around_the_clock_support.title',
    subtitle: 'advantages.around_the_clock_support.subtitle',
    icon: 'map',
  },
  {
    title: 'advantages.on_time_warranty.title',
    subtitle: 'advantages.on_time_warranty.subtitle',
    icon: 'pay',
  },
];

export const CONNECTION: IconCardType[] = [
  {icon: 'hotel-pms', height: 32, width: 155},
  {icon: 'travelline', height: 27, width: 196, href: CONNECT_TRAVELLINE},
  {icon: 'bnovo', height: 28, width: 124, href: CONNECT_BNOVO},
  {icon: 'realty', height: 48, width: 140, href: CONNECT_REALTY_CALENDAR},
];

export const CONNECTION_MOBILE: IconCardType[] = [
  {icon: 'hotel-pms', height: 21, width: 103},
  {icon: 'travelline', height: 18, width: 130, href: CONNECT_TRAVELLINE},
  {icon: 'bnovo', height: 18, width: 82, href: CONNECT_BNOVO},
  {icon: 'realty', height: 32, width: 93, href: CONNECT_REALTY_CALENDAR},
];

export const FAQ: FaqType[] = [
  {
    title: 'index:faq.tabs.register_object.title',
    content: [
      'index:faq.tabs.register_object.text_1',
      'index:faq.tabs.register_object.text_2',
      'index:faq.tabs.register_object.text_3',
      'index:faq.tabs.register_object.text_4',
    ],
    links: [ZHILIBYLI_URL, ZHILIBYLI_MOBILE_APPS],
  },
  {
    title: 'index:faq.tabs.get_money_for_booking.title',
    content: ['index:faq.tabs.get_money_for_booking.text'],
  },
  {
    title: 'index:faq.tabs.contact_guests.title',
    content: ['index:faq.tabs.contact_guests.text'],
    links: [
      ZHILIBYLI_HOTELIERS_URL,
      ZHILIBYLI_HOTELIERS_URL,
      ZHILIBYLI_MOBILE_APPS,
    ],
  },
  {
    title: 'index:faq.tabs.receive_notifications.title',
    content: ['index:faq.tabs.receive_notifications.text'],
  },
  {
    title: 'index:faq.tabs.contact_support.title',
    content: ['index:faq.tabs.contact_support.text'],
    links: [
      ZHILIBYLI_SUPPORT,
      ZHILIBYLI_MOBILE_APPS,
      ZHILIBYLI_TELEGRAM,
      ZHILIBYLI_WHATSAPP,
    ],
  },
];

export const PROMOTIONS: CardType[] = [
  {
    title: 'promotion.promotion_items.verification_check_mark.title',
    subtitle: 'promotion.promotion_items.verification_check_mark.subtitle',
    icon: 'verification-checkbox',
  },
  {
    title: 'promotion.promotion_items.best_offer_mark.title',
    subtitle: 'promotion.promotion_items.best_offer_mark.subtitle',
    icon: 'best-offer',
  },
];

export const ABOUT_TARIFFS: CardType[] = [
  {
    title: 'tariffs.about_tariffs.guaranteed_payment.title',
    subtitle: 'tariffs.about_tariffs.guaranteed_payment.subtitle',
    icon: 'self',
  },
  {
    title: 'tariffs.about_tariffs.partial_payment.title',
    subtitle: 'tariffs.about_tariffs.partial_payment.subtitle',
    icon: 'wallet',
  },
];

// Массив с массивами индексов точек, которые должны отображаться в анимвции (номер grid элемента, который будет анимирован)
export const DOTS = [
  [2, 6, 9, 15],
  [2, 5, 14, 22],
  [1, 2, 7, 14, 15, 19, 24],
  [1, 3, 7, 10, 15, 20, 24, 26, 31],
  [0, 3, 5, 10, 11, 18, 20, 22, 26, 28, 34],
  [0, 2, 5, 6, 9, 11, 13, 15, 18, 19, 22, 23, 27, 28, 30, 32, 34],
];

export const SLIDES = [
  {
    title: 'slider_advantages.slides.monthly_visitors.title',
    accent: 'slider_advantages.slides.monthly_visitors.accent',
  },
  {
    title: 'slider_advantages.slides.daily_bookings.title',
    accent: 'slider_advantages.slides.daily_bookings.accent',
  },
  {
    title: 'slider_advantages.slides.average_booking_cost.title',
    accent: 'slider_advantages.slides.average_booking_cost.accent',
  },
];

export const STATISTICS: CardType[] = [
  {
    title: 'statistics.about_statistics.partners_receive_bookings.title',
    subtitle: 'statistics.about_statistics.partners_receive_bookings.subtitle',
    icon: 'plus',
  },
  {
    title: 'statistics.about_statistics.guests_who_booked.title',
    subtitle: 'statistics.about_statistics.guests_who_booked.subtitle',
    icon: 'repeat',
  },
  {
    title: 'statistics.about_statistics.nights_booked.title',
    subtitle: 'statistics.about_statistics.nights_booked.subtitle',
    icon: 'global',
  },
  {
    title: 'statistics.about_statistics.data_integration.title',
    subtitle: 'statistics.about_statistics.data_integration.subtitle',
    icon: 'statistics',
  },
];
