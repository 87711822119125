
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getLandingPageProperties as __Next_Translate_old_getServerSideProps__194d64671c4__ } from 'slices/landing';
import { SeoProvider } from 'library/components/seo-provider';
import type { NextPage } from 'next';
import useTranslation from 'next-translate/useTranslation';
import type { ApplicationProperties } from 'source/utilities/ui';
import { ExtranetContent } from 'slices/landing';
export interface LandingPageProperties extends ApplicationProperties {
}
const LandingPage: NextPage<LandingPageProperties> = () => {
    const { t } = useTranslation('index');
    return (<SeoProvider config={{
            title: t('seo.title'),
            description: t('seo.description'),
            keywords: t('seo.keywords'),
            author: t('seo.author'),
        }}>
      <ExtranetContent />
    </SeoProvider>);
};
export default LandingPage;
export {} from 'slices/landing';

    async function __Next_Translate__getServerSideProps__194d64671c4__(ctx) {
      const res = await __Next_Translate_old_getServerSideProps__194d64671c4__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__194d64671c4__ as getServerSideProps }
  