import type {GetServerSideProps} from 'next';

import {andThen, call, pipeWith} from 'ramda';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handlePropsException} from 'source/utilities/exceptions/business';

import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';
import {redirects} from 'source/utilities/network/url';

import {removeUndefined} from 'source/utilities/object';
import {ApplicationProperties} from 'source/utilities/ui';

export const getLandingPageProperties: GetServerSideProps<
  ApplicationProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        return removeUndefined(properties);
      },
    ]),
  )
    .then((properties) => {
      if (properties?.authorized) {
        return {redirect: redirects.manage};
      }
      return {
        props: properties,
      };
    })
    .catch(handlePropsException);
};
