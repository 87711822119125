import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import {Text} from '../text';
import {Title} from '../title';
import AnimationSearchInput from '../animation-search-input';
import AnimationSearchResult from '../animation-search-result';

const MainScreenWrapper = styled.div`
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 70px 0 30px 0;
  box-shadow: ${({theme}) => theme.palette.insetShadowExtranet};

  @media (max-width: 1440px) {
    padding-bottom: 46px;
  }

  @media (max-width: 479px) {
    padding: 46px 0 0 0;
  }
`;

const Gradient = styled.div`
  width: 100%;
  height: 200px;
  position: absolute;
  z-index: 100;
  bottom: 0;
  background: ${({theme}) => theme.palette.extranetGraqient};

  @media (max-width: 1440px) {
    height: 168px;
  }

  @media (max-width: 650px) {
    height: 150px;
  }

  @media (max-width: 320px) {
    height: 120px;
  }
`;

const TitleWrapper = styled.div`
  max-width: 650px;
  margin-bottom: 48px;

  @media (max-width: 640px) {
    max-width: 430px;
  }
  @media (max-width: 450px) {
    margin-bottom: 32px;
  }
`;

const SubtitleWrapper = styled.div`
  margin-top: 16px;
`;

const StyledText = styled(Text)`
  max-width: 580px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: 480px;
  }

  @media (max-width: 450px) {
    max-width: 290px;
  }
`;

const BR = styled.span`
  display: block;
`;

const MainScreen = () => {
  const {t} = useTranslation('index');

  return (
    <MainScreenWrapper>
      <TitleWrapper>
        <Title size="XXL" align="center" type="h1">
          {t('main_screen.title_1')}
          <BR />
          {t('main_screen.title_2')} <span>{t('main_screen.title_accent')}</span>
        </Title>
        <SubtitleWrapper>
          <StyledText size="MD" align="center">
            {t('main_screen.subtitle')}
          </StyledText>
        </SubtitleWrapper>
      </TitleWrapper>
      <AnimationSearchInput />
      <AnimationSearchResult />
      <Gradient />
    </MainScreenWrapper>
  );
};

export default MainScreen;
