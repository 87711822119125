import styled from '@emotion/styled';
import {FC, PropsWithChildren} from 'react';
import {Link} from 'source/components/link';

const StylesButton = styled(Link)`
  display: block;
  text-align: center;
  width: fit-content;
  min-width: 261px;
  padding: 13px 32px;
  background: ${({theme}) => theme.palette.primaryAccent};
  color: ${({theme}) => theme.palette.fontAccent};
  border: none;
  border-radius: 8px;
  font-family: ${({theme}) => theme.fontFamily.MONTSERRAT};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  transition: background 500ms;
  &:hover {
    background: ${({theme}) => theme.palette.extranetButtonHover};
  }
  &:active {
    background: ${({theme}) => theme.palette.extranetButtonActive};
  }

  @media (max-width: 450px) {
    min-width: 150px;
    padding: 9px 20px;
    font-size: 11px;
    line-height: 14px;
  }
`;

interface Props extends PropsWithChildren {
  href: string;
}

const ButtonLink: FC<Props> = ({href, children}) => {
  return <StylesButton href={href}>{children}</StylesButton>;
};

export default ButtonLink;
