import {FC, PropsWithChildren} from 'react';
import {css, jsx, Theme, useTheme} from '@emotion/react';

const styles = {
  MD: css`
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    @media (max-width: 1440px) {
      font-size: 20px;
      line-height: 28px;
    }
    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 25px;
    }
    @media (max-width: 450px) {
      font-size: 12px;
      line-height: 16px;
    }
  `,
  XS2: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;

    @media (max-width: 470px) {
      font-size: 10px;
      line-height: 13px;
    }
  `,
  SM: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 67px;
    line-height: 22px;
    @media (max-width: 450px) {
      font-size: 11px;
      line-height: 14px;
    }
  `,
  SMBold: css`
    font-size: 16px;
    font-weight: 700;
    line-height: 67px;
    line-height: 22px;
  `,
  SM2: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: 1440px) {
      font-size: 16px;
      line-height: 22px;
    }
    @media (max-width: 450px) {
      font-size: 11px;
      line-height: 14px;
    }
  `,
  SM3: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  `,
};

type TextSize = keyof typeof styles;
type AlignType = 'center' | 'right' | 'left';

const VariantTextStyle = (
  theme: Theme,
  size: TextSize,
  align?: AlignType,
) => css`
  text-align: ${align ?? 'left'};
  font-family: ${theme.fontFamily.MONTSERRAT};
  color: ${theme.palette.fontSecondaryExtranet};
  ${styles[size]}
`;

interface Props extends PropsWithChildren {
  size: TextSize;
  className?: string;
  type?: 'p' | 'span';
  align?: AlignType;
}

export const Text: FC<Props> = ({
  children,
  size = 'XS2',
  className,
  type = 'p',
  align,
}) => {
  const theme = useTheme();
  const css = VariantTextStyle(theme, size, align);
  return jsx(type, {className, css}, children);
};
