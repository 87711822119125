import {FC, PropsWithChildren} from 'react';
import {css, jsx, Theme, useTheme} from '@emotion/react';

const styles = {
  SM2: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    @media (max-width: 480px) {
      font-size: 12px;
      line-height: 16px;
    }
  `,
  SM3: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    @media (max-width: 470px) {
      font-size: 13px;
      line-height: 18px;
    }
  `,
  XL: css`
    font-size: 48px;
    font-weight: 500;
    line-height: 57px;
    @media (max-width: 1440px) {
      font-size: 32px;
      line-height: 38px;
    }
    @media (max-width: 450px) {
      font-size: 21px;
      line-height: 25px;
    }
  `,
  XXL: css`
    font-size: 56px;
    font-weight: 500;
    line-height: 67px;
    @media (max-width: 1440px) {
      font-size: 48px;
      line-height: 57px;
    }
    @media (max-width: 640px) {
      font-size: 32px;
      line-height: 38px;
    }
    @media (max-width: 450px) {
      font-size: 21px;
      line-height: 25px;
    }
  `,
};

type TitleSize = keyof typeof styles;
type AlignType = 'center' | 'right' | 'left';

const VariantTitleStyle = (
  theme: Theme,
  size: TitleSize,
  align?: AlignType,
) => css`
  font-family: ${theme.fontFamily.MONTSERRAT};
  text-align: ${align ?? 'left'};
  color: ${theme.palette.fontExtranet};
  ${styles[size]}

  span {
    color: ${theme.palette.primaryAccent};
  }
`;

interface Props extends PropsWithChildren {
  size: TitleSize;
  className?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  align?: AlignType;
}

export const Title: FC<Props> = ({
  children,
  size = 'XL',
  className,
  type = 'h1',
  align,
}) => {
  const theme = useTheme();
  const css = VariantTitleStyle(theme, size, align);
  return jsx(type, {className, css}, children);
};
