import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import {
  DELAY_MULPLIER,
  NUMBER_OF_NOTIFICATIONS_IN_ANIMATION,
} from 'slices/landing/lib/constants';
import phoneScreenImage from 'source/components/illustration/assets/phone-screen.webp';
import notification from 'source/components/illustration/assets/notification.webp';

const Wrapper = styled.div`
  position: relative;
  transform: translateY(100px);
  padding: 0 89px;

  @media (max-width: 1023px) {
    transform: translateY(0);
    padding: 0 53px;
    max-width: 255px;
  }

  @media (max-width: 625px) {
    position: absolute;
    width: 248px;
    right: 0;
  }

  @media (max-width: 555px) {
    position: absolute;
    right: 23px;
    padding-top: 90px;
    overflow: hidden;
    width: 173px;
    transform: translate(32px, -60px);
  }

  @media (max-width: 480px) {
    transform: translate(10px, -45px);
    right: 10px;
  }

  @media (max-width: 450px) {
    padding-top: 0;
    width: 200px;
    transform: translateX(10px);
  }

  @media (max-width: 375px) {
    width: 150px;
    position: absolute;
    right: 0;
  }
`;

const ImageWrapper = styled.div`
  img {
    width: 248px;
    object-fit: cover;
    background: transparent;
    object-position: center;
  }

  @media (max-width: 1023px) {
    width: 146px;
    img {
      width: 146px;
      height: fit-content;
    }
  }

  @media (max-width: 640px) {
    img {
      width: 146px;
    }
  }

  @media (max-width: 625px) {
    overflow: hidden;
    width: 146px;
  }

  @media (max-width: 450px) {
    img {
      width: auto;
      height: 230px;
    }
  }
`;

const AnimationWrapper = styled.div`
  position: absolute;
  max-width: 425px;
  width: 100%;
  top: 15px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1023px) {
    top: 0;
  }

  @media (max-width: 555px) {
    width: 248px;
    top: 90px;
    left: 0;
  }

  @media (max-width: 450px) {
    top: 0;
  }
`;

const PhoneWrapper = styled.div`
  position: relative;
  width: 425px;
  height: 104px;
  transform-style: preserve-3d;

  @media (max-width: 1023px) {
    width: 230px;
    top: -5px;
  }

  @media (max-width: 625px) {
    width: 248px;
  }

  @media (max-width: 450px) {
    width: 255px;
    top: -5px;
  }
`;

const NotificationImage = styled(Image)`
  position: relative;
  width: 398px;
  height: 104px;
  transform-style: preserve-3d;
  background: transparent;
  object-fit: contain;
  object-position: top;

  @media (max-width: 1023px) {
    width: 288px;
  }

  @media (max-width: 625px) {
    width: 248px;
  }
  @media (max-width: 555px) {
    width: 288px;
  }
`;

const IconWrapper = styled.div<{delay: number}>`
  @keyframes animate {
    0% {
      transform: perspective(500px) translate3d(0, -90px, 0);
      z-index: 3;
      opacity: 0;
    }
    20% {
      transform: perspective(500px) translate3d(0, 0, 0);
      z-index: 3;
      opacity: 1;
    }
    50% {
      transform: translateY(15%) scale(0.9);
      z-index: 2;
      opacity: 1;
    }
    80% {
      transform: translateY(25%) scale(0.8);
      z-index: 1;
    }
    100% {
      transform: translateY(25%) scale(0.8);
      opacity: 0;
    }
  }
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  overflow: hidden;
  animation: animate 6s linear infinite;
  animation-delay: ${({delay}) => delay}s;

  @media (max-width: 1023px) {
    max-width: 288px;
  }
`;

const AnimationNotification = () => {
  const {t} = useTranslation('index');

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={phoneScreenImage} alt={t('tariffs.title')} />
      </ImageWrapper>
      <AnimationWrapper>
        <PhoneWrapper>
          {Array.from(
            {length: NUMBER_OF_NOTIFICATIONS_IN_ANIMATION},
            (_, index) => {
              return (
                <IconWrapper delay={(index + 1) * DELAY_MULPLIER} key={index}>
                  <NotificationImage
                    src={notification}
                    alt={t('account.alt')}
                  />
                </IconWrapper>
              );
            },
          )}
        </PhoneWrapper>
      </AnimationWrapper>
    </Wrapper>
  );
};

export default AnimationNotification;
