import styled from '@emotion/styled';
import {ADVANTAGES} from 'slices/landing/lib/constants';
import AdvantageItem from '../advantage-item';
import ScrollContainer from '../scroll-container';

const Box = styled.div`
  margin: 45px auto 0;
  transform: translateY(-70px);
  margin-bottom: -35px;
  position: relative;
  z-index: 100;

  @media (max-width: 1023px) {
    transform: translateY(-65px);
  }
  @media (max-width: 480px) {
    margin-bottom: -35px;
  }
  @media (max-width: 450px) {
    margin-bottom: -65px;
  }
`;

const Advantages = () => {
  return (
    <Box>
      <ScrollContainer>
        {ADVANTAGES.map((advantage) => (
          <AdvantageItem
            key={advantage.title}
            title={advantage.title}
            subtitle={advantage.subtitle}
            icon={advantage.icon}
          />
        ))}
      </ScrollContainer>
    </Box>
  );
};

export default Advantages;
